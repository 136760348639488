import { Box, Container, Grid } from "@mui/material"
import { createContext, useState } from "react"
import CustomFilter from "./components/CustomFilter"
import FilterTabs from "./components/FilterTabs"
import ImageField from "./components/ImageField"
import InstaFitler from "./components/InstaFitler" 
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import "./App.css"

export const FilterContext = createContext();


function App() {
  const [tabFilter, setTabFilter] = useState('instaFilter');
  const [filterClass, setFilterClass] = useState("");
  const [customFilter, setCustomFilter] = useState({
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    gray: 0
  });
  const [imageSize, setImageSize] = useState({
    width:'100%',
    height:'100%'
  });

  const value = {
    tabFilter,
    setTabFilter,
    filterClass,
    setFilterClass,
    customFilter,
    setCustomFilter,
    imageSize,
    setImageSize
  }
  return (
    <FilterContext.Provider value={value}>
      <Container sx={{ marginTop: '4rem', marginBottom: '4rem' }} id="container">
        <Box sx={{ textAlign: 'center', marginBottom: '3rem' }}>
          <h1>Image Filter</h1>
        </Box>
        <Grid container spacing={10}>
          <ImageField />           
        </Grid>
        <div style={{paddingBottom: '100px'}}>
        {/* {tabFilter === 'instaFilter' ? <InstaFitler /> : <CustomFilter />} */}
        </div>
      </Container>
      <FilterTabs />
    </FilterContext.Provider>
  );
}

export default App;
