import { Box, Tab, Tabs } from "@mui/material"
import { useContext } from "react"
import { FilterContext } from "../App";
import Slider from "react-slick";
import { filterValues } from '../util/utils';
import RajKumar from "../styles/img/Raj_Kumar_Gupt.jpeg"

const FilterTabs = () => {
  const {tabFilter, setTabFilter, setFilterClass} = useContext(FilterContext);

  const handleChange = (e, newValue) => {
    setTabFilter(newValue);
    if(newValue === 'customFilter') {
      setFilterClass('');
    }
  }
  const handleClick = (value) =>{
    setFilterClass(value)
  }

  const settings = {
     
    speed: 1000, 
    slidesToShow: 10,
    slidesToScroll: 10, 
    responsive: [
      {
        breakpoint: 901,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
          initialSlide: 8
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          initialSlide: 6
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4, 
          initialSlide: 4
        }
      }
    ]
     
  };

  return (
    <Box   id="footer">
      {/* <Tabs
        value={tabFilter}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        className="font-white"
      >
        <Tab value="instaFilter" label="Instagram Filter" />
        <Tab value="customFilter" label="Custom Filter" />
      </Tabs> */}
      <div>

      
      <Slider {...settings} className="footer-carousel">

      {filterValues.map(filter =>  
      <div key={filter.class} onClick={()=>handleClick(filter.class)}>         
         <img src={RajKumar} alt="Moneytree" className={filter.class} />
          <label>{filter.name}</label>
      </div>
      )}       
    </Slider>
    </div>

    </Box>
  )
}

export default FilterTabs